import React from 'react';

const Contact = () => {
  return (
    <div className='h-screen flex items-center justify-center'>
        Contact
    </div>
  )
}

export default Contact