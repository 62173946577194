import React from 'react';
import Button from './Button';
import ScrambleText from './ScrambleText';

const Navbar = () => {

  const email = 'denimdigitalagency@gmail.com'

  return (
    <div className='flex justify-between items-center h-32 w-full border-b border-amber-200 border-b-1 bg-stone-900 pl-5 pr-5 top-0 md:pl-10 md:pr-10'>
      
      <a href='/'>
        <ScrambleText /> 
      </a>

      <a href={`mailto:${email}`}>
        <Button>
          Contact
        </Button>
      </a>

    </div>
  )
}

export default Navbar