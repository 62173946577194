import React from 'react';

const Button = ({children}) => {
  return (
    <button className='items-center justify-center bg-amber-500 h-8 w-24 md:h-12 md:w-28 rounded-full hover:bg-amber-500 hover:text-amber-700'>
      <span className='text-l text-amber-900 md:text-xl'>
        {children}
      </span>
    </button>
  )
}

export default Button