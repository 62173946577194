import React from 'react';

const About = () => {
  return (
    <div className='container mx-auto p-8 h-screen mb-20'>
      <div className='mt-12'>
        <h1 className='text-4xl font-semibold text-amber-500 mb-6'>About Us</h1>
        <p className='text-3xl text-amber-200 leading-7'>
          At Denim Digital Agency, we are dedicated to helping businesses thrive in the digital age. <br/> 
          Our global team of experts is passionate about crafting innovative solutions and delivering measurable results.
        </p>
      </div>

      <div className='mt-12'>
        <h2 className='text-4xl font-semibold text-amber-500 mb-6'>Our Mission</h2>
        <p className='text-3xl text-amber-200 leading-7'>
          Our mission is to empower businesses with the digital tools they need to succeed. <br/> 
          We provide the highest quality services to create lasting partnerships with our clients.
        </p>
      </div>

      <div className='mt-12'>
        <h2 className='text-4xl font-semibold text-amber-500 mb-6'>Why Choose Us</h2>
        <ul className='list-disc text-3xl text-amber-200 pl-6'>
          <li>Experienced Team: Our team consists of seasoned professionals with a track record of success.</li>
          <li>Tailored Solutions: We customize our services to meet the unique needs of each client.</li>
          <li>Cutting-Edge Technology: We stay up-to-date with the latest industry trends and technologies.</li>
          <li>Results-Driven: Our focus is on achieving tangible results for your business.</li>
        </ul>
      </div>

    </div>
  );
}

export default About;
