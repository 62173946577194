import React from 'react';

const Footer = () => {
  return (
    <div className='flex justify-center items-center h-32 pl-5 pr-5 bg-stone-900 text-amber-200 border-t border-1 border-amber-200 md:pl-10 md:pr-10'>
      <div>
        &copy; {new Date().getFullYear()} Denim Digital Agency.
      </div>
    </div>
  );
}

export default Footer;
