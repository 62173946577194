import React from 'react'

const ErrorPage = () => {
  return (
    <div className='h-screen flex items-center justify-center'>
      ERROR, DESTROY YOUR DEVICE!
    </div>
  )
}

export default ErrorPage