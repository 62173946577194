import React from 'react';
import About from './components/About';
import Hero from './components/Hero';

function App() {
  return (
    <>
      <Hero />
      {/* <Contact /> */}
    </>
  );
}

export default App;
