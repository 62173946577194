import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import App from './App';
import Contact from './components/Contact';
import ErrorPage from './components/ErrorPage';
import About from './components/About';
import LocomotiveScroll from 'locomotive-scroll';
import Layout from './components/Layout';

const locomotiveScroll = new LocomotiveScroll();

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <App />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: (
      <Layout>
        <Contact />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: (
      <Layout>
        <About />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
);
