import React from 'react'

const Hero = () => {
  return (
    <div className='flex h-screen items-center justify-center font-thin pl-5 pr-5 md:pl-10 md:pr-10'>
      <p className='text-5xl'>Hi. <br/>
        We're just getting started. <br/> 
        In the meantime, go ahead and click that <span className='text-amber-500'>Contact</span> button up there.
      </p>
    </div>
  )
}

export default Hero